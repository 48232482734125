import React, { useEffect } from "react"

//import { motion, useAnimation } from "framer-motion";

import 'styles/index.scss'

//import Container from 'components/ui/layout/Container'
//import ProjectThumbnailList from 'components/ui/projectthumbnaillist/ProjectThumbnailList'
import Layout from 'components/ui/layout/Layout'

// markup
const HomePage = ({ children, transitionStatus, entry, exit, location }) => {

  return (
    <Layout
      transitionStatus={transitionStatus}
      entry={entry}
      exit={exit}
      isInfoPage={false}
      isHomePage={true}
      location={location}
    />
  )
}


export default HomePage
